import { BaseService } from "./base.service";
import {
  AddTeamMemberDto,
  ShortTeamMember,
  TeamMember,
  TeamMemberListResponse,
  UpdateTeamMemberDto,
  UpdateTeamMemberLoginEmailDto,
} from "../models/team.model";
import { QueryOpts } from "../models/base.model";

export class TeamService extends BaseService {
  getTeamMember(storeId: string, teamMemberId: string): Promise<TeamMember> {
    const reqUrl = `teams/${storeId}/${teamMemberId}/`;
    const req = this.apiClient.get(reqUrl);

    return this.makeRequest(req, reqUrl);
  }

  getTeamMembers(
    storeId: string,
    queryOpts: QueryOpts,
  ): Promise<TeamMemberListResponse> {
    const reqUrl = `teams/${storeId}/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts.filters,
    });

    return this.makeRequest(req, reqUrl);
  }

  getAllTeamMembers(
    storeId: string,
    queryOpts?: QueryOpts,
  ): Promise<ShortTeamMember[]> {
    const reqUrl = `teams/${storeId}/all/`;
    const req = this.apiClient.get(reqUrl, {
      params: queryOpts?.filters,
    });

    return this.makeRequest(req, reqUrl);
  }

  addTeamMember(
    storeId: string,
    teamMember: AddTeamMemberDto,
  ): Promise<TeamMember> {
    const reqUrl = `teams/${storeId}/`;
    const req = this.apiClient.post(reqUrl, teamMember);

    return this.makeRequest(req, reqUrl);
  }

  addLoginEmailForTeamMember(
    storeId: string,
    teamMemberId: string,
    data: UpdateTeamMemberLoginEmailDto,
  ): Promise<TeamMember> {
    const reqUrl = `teams/${storeId}/${teamMemberId}/actions/update-login/`;
    const req = this.apiClient.post(reqUrl, data);

    return this.makeRequest(req, reqUrl);
  }

  updateTeamMember(
    storeId: string,
    teamMemberId: string,
    data: UpdateTeamMemberDto,
  ): Promise<TeamMember> {
    const reqUrl = `teams/${storeId}/${teamMemberId}/`;
    const req = this.apiClient.patch(reqUrl, data);

    return this.makeRequest(req, reqUrl);
  }

  removeTeamMember(storeId: string, teamMemberId: string): Promise<void> {
    const reqUrl = `teams/${storeId}/${teamMemberId}/`;
    const req = this.apiClient.delete(reqUrl);

    return this.makeRequest(req, reqUrl);
  }

  restoreTeamMember(storeId: string, teamMemberId: string): Promise<void> {
    const reqUrl = `teams/${storeId}/${teamMemberId}/restore/`;
    const req = this.apiClient.post(reqUrl);

    return this.makeRequest(req, reqUrl);
  }
}
