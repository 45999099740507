import { getKeyFromLocalStorage, Store, User, UserService } from "@eleo/core";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { NavigationProps } from "@eleo/components/components/Navigation/Navigation";
import {
  LayoutOptions,
  OjaUIProvider,
} from "@eleo/components/components/OjaUIProvider/OjaUIProvider";
import { useQuery } from "react-query";
import { deleteCookie } from "cookies-next";
import { useRouter } from "next/router";
import { buildNavGroups } from "../services/nav";
import { getPerformance } from "firebase/performance";
import { AUTH_COOKIE_ID, storageKeys } from "../config/constants";
import dynamic from "next/dynamic";
import { NavigationGroup } from "@eleo/components/components/Navigation/NavGroup";
import { useUserStorePermissions } from "@eleo/core/modules/suite/user/user.hooks";

const FloatingActionsButton = dynamic(() =>
  import(
    "@eleo/components/components/FloatingActionButton/FloatingActionButton"
  ).then((mod) => mod.FloatingActionsButton),
);

export interface AuthContextProps {
  currentStore: Store | undefined;
  setCurrentStore: (storeId: Store | undefined) => void;
  user: User | undefined;
  setUser: (user?: User | null) => void;
  hasRoleOnStore: (role: string) => boolean; // Role the user has on the current store
  hasPermissionFunc: (permission: string) => boolean;
}

const AuthContext = createContext<AuthContextProps>({
  currentStore: undefined,
  setCurrentStore: () => null,
  user: undefined,
  setUser: () => null,
  hasRoleOnStore: () => false,
  hasPermissionFunc: () => false,
});

export const useUserData = () => useContext(AuthContext);

export interface AuthProviderProps {
  layoutOptions?: LayoutOptions;
  navGroups?: NavigationGroup[];
}

const getStoreFromLocalStorage = (): Store | undefined => {
  const storeJson = getKeyFromLocalStorage(storageKeys.currentStore);

  if (!storeJson) {
    return undefined;
  }

  return JSON.parse(storeJson) as Store;
};

export const AuthProvider = ({
  children,
  layoutOptions,
  navGroups,
}: PropsWithChildren<AuthProviderProps>) => {
  const [currentStore, setCurrentStore] = useState<Store | undefined>();
  const router = useRouter();

  const userService = new UserService();

  const userDetails = useQuery<User>(
    "get-current-user",
    () => userService.getCurrentUser(),
    {},
  );

  const user = userDetails.data;

  const userPermissionsQuery = useUserStorePermissions(
    user?.id || "",
    currentStore?.id || "",
  );
  const userPermissions: string[] =
    userPermissionsQuery.data?.permissions || [];

  const showFloatingActions = user && !layoutOptions?.hideFloatingActions;

  const navOptions: NavigationProps = {
    setCurrentStore,
    handleLogout() {
      const auth = getAuth();

      auth.signOut().then(() => {
        deleteCookie(AUTH_COOKIE_ID);

        localStorage.removeItem(storageKeys.currentStore);

        router.push("/auth/login");
      });
    },
    logo: "/images/logo.svg",
    navGroups: navGroups || buildNavGroups(currentStore, userDetails.data),
    store: currentStore,
    user,
  };

  const hasRoleOnStore = (role: string): boolean => {
    if (!currentStore || !user || !user.store_roles) {
      return false;
    }

    const roleObj = user.store_roles.find(
      (r) => r.store_id === currentStore.id,
    );

    return Boolean(roleObj && roleObj.role === role);
  };

  // Load the current store if available from the localStorage
  useEffect(() => {
    const store = getStoreFromLocalStorage();
    setCurrentStore(store);
  }, []);

  // Firebase initialization & setup
  // If a user doesn't have an existing store, redirect to the store creation page
  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    };
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    getPerformance(app);

    const auth = getAuth();

    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser && !userDetails.data) {
        userDetails.refetch();
      }
    });
  }, []);

  // Redirect the user to create a store if they have no store
  useEffect(() => {
    const createStoreRoute = "/store/create";
    if (
      router?.asPath !== createStoreRoute &&
      user &&
      user.stores.length === 0
    ) {
      router.push(createStoreRoute);
    }
  }, [user]);

  // Set the currentStore if the user has a store
  useEffect(() => {
    const persistedStoreId = getStoreFromLocalStorage()?.id;
    const store = persistedStoreId
      ? user?.stores.find((store) => `${store.id}` === persistedStoreId)
      : user?.stores[0];
    if (store) {
      setCurrentStore(store);
    }
  }, [user?.stores]);

  // When the current store is updated, persist it to the device storage
  useEffect(() => {
    if (currentStore) {
      localStorage.setItem(
        storageKeys.currentStore,
        JSON.stringify(currentStore),
      );
    }
  }, [currentStore]);

  return (
    <AuthContext.Provider
      value={{
        currentStore,
        setCurrentStore,
        user: userDetails.data,
        setUser: () => userDetails.refetch(),
        hasPermissionFunc: (permission: string) =>
          userPermissions.includes(permission),
        hasRoleOnStore,
      }}
    >
      {showFloatingActions && (
        <FloatingActionsButton
          actions={[
            {
              label: "Add Transaction",
              link: "/transactions/add",
              visible: hasRoleOnStore("owner"),
            },

            {
              label: "Receive Stock",
              link: "/smart-stock/add",
            },
            {
              label: "New Order",
              link: "/orders/new",
            },
          ]}
        />
      )}

      <OjaUIProvider
        navProps={navOptions}
        currentPagePath={router?.asPath || ""}
        layoutOptions={layoutOptions}
      >
        {children}
      </OjaUIProvider>
    </AuthContext.Provider>
  );
};
