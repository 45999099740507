import type { NextWebVitalsMetric } from "next/app";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import type { LayoutOptions } from "@eleo/components/components/OjaUIProvider/OjaUIProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import "firebase/auth";
import "firebase/performance";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../contexts/auth.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import type React from "react";
import { useEffect } from "react";
import "react-phone-number-input/style.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import { setupEleoApp } from "@eleo/core";
import { logger } from "@eleo/core/libs/logger";
import withAuth from "@eleo/suite/components/withAuth/withAuth";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { bodyFont } from "@eleo/components";

const muiTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: "#11142D",
          fontFamily: bodyFont,
          fontSize: "14px",
        },
      },
    },
  },
} as any);

if (
  typeof window !== "undefined" &&
  process.env.NEXT_PUBLIC_POSTHOG_KEY &&
  posthog
) {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as any, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://eu.i.posthog.com",
    person_profiles: "always", // or 'always' to create profiles for anonymous users as well
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
  });
}

export interface CustomAppProps extends AppProps {
  Component: React.ComponentType & {
    layoutOptions?: LayoutOptions;
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
    },
  },
});

const EleoSuite = ({ Component, pageProps, router }: CustomAppProps) => {
  const layoutOptions = Component.layoutOptions || {};

  const AuthComponent = withAuth(Component, router);

  useEffect(() => {
    setupEleoApp({
      serviceName: "suite",
    });
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />

        <AuthProvider layoutOptions={layoutOptions}>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={muiTheme}>
              <AuthComponent {...pageProps} />
            </ThemeProvider>
          </DndProvider>
        </AuthProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
};

EleoSuite.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps> => {
  const ctx = await App.getInitialProps(context);

  return { ...ctx };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  logger.info(JSON.stringify(metric));
}

export default EleoSuite;
