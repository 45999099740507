import { useMutation, useQuery } from "react-query";
import {
  AddTeamMemberDto,
  ShortTeamMember,
  TeamMember,
  TeamMemberListResponse,
  UpdateTeamMemberDto,
  UpdateTeamMemberLoginEmailDto,
} from "../models/team.model";
import { TeamService } from "../services/team.service";
import { QueryOpts } from "../models/base.model";

export const useCreateTeamMember = () =>
  useMutation<TeamMember, Error, { storeId: string; data: AddTeamMemberDto }>(
    ({ data: teamMember, storeId }) => {
      const teamService = new TeamService();
      return teamService.addTeamMember(storeId, teamMember);
    },
  );

export const useUpdateTeamMember = () =>
  useMutation<
    TeamMember,
    Error,
    { storeId: string; teamMemberId: string; data: UpdateTeamMemberDto }
  >(({ data, storeId, teamMemberId }) => {
    const teamService = new TeamService();
    return teamService.updateTeamMember(storeId, teamMemberId, data);
  });
export const useUpdateTeamMemberLoginEmail = (
  storeId: string,
  teamMemberId: string,
) =>
  useMutation<TeamMember, Error, UpdateTeamMemberLoginEmailDto>((data) => {
    const teamService = new TeamService();
    return teamService.addLoginEmailForTeamMember(storeId, teamMemberId, data);
  });

export const useRemoveTeamMemberMutation = () =>
  useMutation<void, Error, { storeId: string; teamMemberId: string }>(
    ({ storeId, teamMemberId }) => {
      const teamService = new TeamService();
      return teamService.removeTeamMember(storeId, teamMemberId);
    },
  );
export const useRestoreTeamMemberMutation = () =>
  useMutation<void, Error, { storeId: string; teamMemberId: string }>(
    ({ storeId, teamMemberId }) => {
      const teamService = new TeamService();
      return teamService.restoreTeamMember(storeId, teamMemberId);
    },
  );

export const useGetTeamMembersForStore = (
  storeId: string,
  queryOpts: QueryOpts,
) =>
  useQuery<TeamMemberListResponse, Error>(
    [`store_team_${storeId}`, queryOpts],
    async () => {
      const teamService = new TeamService();

      return teamService.getTeamMembers(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );
export const useGetAllTeamMembersForStore = (
  storeId: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<ShortTeamMember[], Error>(
    `all_store_team_${storeId}`,
    async () => {
      const teamService = new TeamService();

      return teamService.getAllTeamMembers(storeId, queryOpts);
    },
    {
      enabled: Boolean(storeId),
    },
  );

export const useGetTeamMemberForStore = (
  storeId: string,
  teamMemberId: string,
  queryOpts?: QueryOpts,
) =>
  useQuery<TeamMember, Error>(
    `store_team_${storeId}_${teamMemberId}`,
    async () => {
      const teamService = new TeamService();

      return teamService.getTeamMember(storeId, teamMemberId);
    },
    {
      enabled: Boolean(storeId && teamMemberId),
      refetchOnMount: queryOpts?.noRefetch ?? undefined,
      refetchOnReconnect: queryOpts?.noRefetch ?? undefined,
      refetchOnWindowFocus: queryOpts?.noRefetch ?? undefined,
    },
  );
