import { useQuery } from "react-query";
import { UserService } from "./user.service";
import { UserStorePermissions } from "./user.model";

export const useUserStorePermissions = (userId: string, storeId: string) =>
  useQuery<UserStorePermissions, Error>(
    ["user-store-permissions", userId, storeId],
    () => {
      const userService = new UserService();

      return userService.getUserStorePermissions(userId, storeId);
    },
    {
      enabled: Boolean(userId && storeId),
    },
  );
