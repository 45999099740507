export const formatMoney = (
  money: number | string,
  currency: string,
  locale: string,
): string => {
  if (!money) {
    return "";
  }

  if (!locale) {
    return "";
  }

  const moneyNumber = typeof money === "string" ? parseFloat(money) : money;

  const hasDecimals = moneyNumber % 1 !== 0;

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  });

  return formatter.format(moneyNumber || 0);
};

export enum NumberFormatOptions {
  Compact = "compact",
  NonCompact = "non-compact",
}

export const humanFriendlyNumber = (
  val: number | string,
  locale: string,
  currency?: string,
  numberFormat?: NumberFormatOptions,
): string => {
  if (!locale) {
    return "";
  }

  const params: Intl.NumberFormatOptions = {
    compactDisplay: "short",
    notation: "compact",
  };

  if (numberFormat === NumberFormatOptions.NonCompact) {
    params.compactDisplay = "long";
    params.notation = "standard";
  }

  if (currency) {
    params.style = "currency";
    params.currency = currency;
    params.currencyDisplay = "narrowSymbol";
  }

  const formatter = new Intl.NumberFormat(locale, params);

  return formatter.format((val as number) || 0);
};

export const calculatePercentage = (
  previous: number,
  current: number,
): string => {
  if (previous === 0) {
    return "0";
  }

  const val = ((current - previous) / previous) * 100;
  return val.toFixed(0);
};
