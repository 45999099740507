import { User, UserStorePermissions } from "./user.model";
import { ApiClientResponse } from "../../../models/responses";
import { getAuth } from "firebase/auth";
import { BaseService } from "../../../services/base.service";

export class UserService extends BaseService {
  async getCurrentUser(): Promise<User> {
    const auth = getAuth();

    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error("User is not logged in");
    }

    return this.getUserByUid(currentUser.uid);
  }

  getUser(uid: string): Promise<User> {
    const reqUrl = `users/${uid}/`;
    const req = this.apiClient.get<ApiClientResponse<User>>(reqUrl);
    return this.makeRequest<User>(req, reqUrl);
  }

  getUserStorePermissions(
    userId: string,
    storeId: string,
  ): Promise<UserStorePermissions> {
    const reqUrl = `users/permissions/${storeId}/`;
    const req =
      this.apiClient.get<ApiClientResponse<UserStorePermissions>>(reqUrl);
    return this.makeRequest<UserStorePermissions>(req, reqUrl);
  }

  getUserByUid(uid: string): Promise<User> {
    const reqUrl = `users/${uid}/uid/`;
    const req = this.apiClient.get<ApiClientResponse<User>>(reqUrl);
    return this.makeRequest<User>(req, reqUrl);
  }
}
