import { PermissionsModelV1, Store, StoreRoles, User } from "@eleo/core";
import { NavigationItem } from "@eleo/components/components/Navigation/NavItem";
import { NavigationGroup } from "@eleo/components/components/Navigation/NavGroup";

const allNavGroups: NavigationGroup[] = [
  {
    items: [
      {
        href: "/dashboard",
        icon: "/images/icons/dashboard.svg",
        id: "store.basic",
        subItems: [
          {
            href: "/dashboard",
            id: "store.basic",
            title: "Overview",
          },
        ],
        title: "Overview",
      },
      {
        href: "/inventory",
        icon: "/images/icons/inventory.svg",
        id: "inventory.basic",
        title: "Inventory",
        subItems: [
          {
            href: "/inventory",
            id: "inventory.basic",
            title: "Overview",
          },
          {
            href: "/inventory/new",
            id: "inventory.basic.add",
            title: "New Product",
          },

          {
            href: "/inventory/stock",
            id: "inventory.basic.stock",
            title: "Manage Stock",
            config: {},
          },

          {
            href: "/inventory/stock/history",
            id: "inventory.basic.stockHistory",
            title: "Stock History",
            config: {
              hideInTopNav: true,
            },
          },
          // {
          //   href: "/inventory/settings",
          //   id: "inventory.basic.settings",
          //   title: "Settings",
          // },
        ],
      },
      {
        href: "/orders",
        icon: "/images/icons/orders.svg",
        id: "orders.basic",
        title: "Orders",
        subItems: [
          {
            href: "/orders",
            id: "order.basic",
            title: "Overview",
          },
          {
            href: "/orders/new",
            id: "order.add",
            title: "New Order",
          },
          {
            href: "/orders/outlets",
            id: "order.express",
            title: "Outlet Mode",
          },
        ],
      },
      // {
      //   href: "/financials/",
      //   icon: dynamic(
      //     () => import("../../../libs/core/assets/icons/overview.svg")
      //   ),
      //   id: "finances.basic",
      //   title: "Financials",
      // },
      {
        href: "/customers",
        icon: "/images/icons/customers.svg",
        id: "customers.basic",
        notification: {
          count: 10,
        },
        title: "Customers",
        subItems: [
          {
            href: "/customers",
            id: "customer.basic",
            title: "Overview",
          },
          {
            href: "/customers/add",
            id: "customer.basic.add",
            title: "Add Customer",
          },
          {
            href: "/customers/debtors",
            id: "customer.basic.debtors",
            title: "Debtors",
          },
        ],
      },
      // {
      //   href: "/messages",
      //   icon: dynamic(
      //     () => import("../../../libs/core/assets/icons/overview.svg")
      //   ),
      //   id: "messages.basic",
      //   notification: {
      //     count: 10,
      //   },
      //   title: "Messages",
      // },
    ],
    title: "Inventory Management",
  },

  {
    items: [
      {
        href: "/menu",
        icon: "/images/icons/menu.svg",
        id: "menu.basic",
        title: "Restaurant Menu",
        subItems: [
          {
            href: "/menu",
            id: "menu.basic",
            title: "Overview",
          },
        ],
      },
    ],
    title: "Storefront",
    permissions: {
      adminOnly: true,
    },
  },
  // {
  //   items: [
  //     {
  //       href: "/farm/overview",
  //       icon: dynamic(
  //         () => import("../../../libs/core/assets/icons/overview.svg")
  //       ),
  //       id: "farm.basic",
  //       title: "Overview",
  //     },
  //     {
  //       href: "/farm/production",
  //       icon: dynamic(
  //         () => import("../../../libs/core/assets/icons/overview.svg")
  //       ),
  //       id: "farm.production",
  //       title: "Production",
  //     },
  //   ],
  //   title: "Farm",
  // },
  {
    items: [
      {
        href: "/payments",
        icon: "/images/icons/files.svg",
        id: "payments.basic",
        title: "Customer Payments",
        subItems: [
          {
            href: "/payments",
            id: "payments.basic",
            title: "Overview",
          },
        ],
      },
      {
        href: "/transactions",
        icon: "/images/icons/expenses.svg",
        id: "transactions",
        title: "Accounting",
        permissions: {
          role: StoreRoles.Owner,
        },
        subItems: [
          {
            href: "/transactions",
            id: "transactions.basic",
            title: "Overview",
          },
          {
            href: "/transactions/accounts",
            id: "transactions.accounts.basic",
            title: "Accounts",
          },
        ],
      },
    ],
    title: "Finances",
  },

  {
    items: [
      {
        href: "/sales-performance",
        icon: "/images/icons/suppliers.svg",
        id: "sales.basic",
        title: "Sales Performance",
        subItems: [
          {
            href: "/sales-performance",
            id: "sales.basic",
            title: "Overview",
          },
          {
            href: "/sales/add",
            id: "sales.add",
            title: "New Sales Target",
          },
        ],
      },

      {
        href: "/campaigns",
        icon: "/images/icons/suppliers.svg",
        id: "campaigns.basic",
        title: "Campaigns",
        subItems: [
          {
            href: "/campaigns",
            id: "campaigns.basic",
            title: "Overview",
          },
          {
            href: "/campaigns/new",
            id: "campaigns.add",
            title: "New Campaign",
          },
        ],
      },

      {
        href: "/loyalty",
        icon: "/images/icons/suppliers.svg",
        id: "loyalty.basic",
        title: "Loyalty Programs",
        subItems: [
          {
            href: "/loyalty",
            id: "loyalty.basic",
            title: "Overview",
          },
          {
            href: "/loyalty/add",
            id: "loyalty.add",
            title: "New Loyalty Program",
          },
        ],
      },
    ],
    title: "Sales",
    permissions: {
      adminOnly: true,
    },
  },

  {
    items: [
      {
        href: "/suppliers",
        icon: "/images/icons/suppliers.svg",
        id: "supply.any",
        title: "Suppliers",
        subItems: [
          {
            href: "/suppliers",
            id: "supply.basic",
            title: "Suppliers",
          },
          {
            href: "/materials",
            id: "supply.basic.materials",
            title: "Materials",
          },
        ],
      },
      {
        href: "/smart-stock",
        icon: "/images/icons/suppliers.svg",
        id: "supply.smart",
        title: "Smart Stock",
        subItems: [
          {
            href: "/smart-stock",
            id: "supply.smart",
            title: "Smart Stock",
          },
          {
            href: "/smart-stock/add",
            id: "supply.basic.materials",
            title: "Receive Stock",
          },
        ],
      },
      {
        href: "/prodtrack",
        icon: "/images/icons/suppliers.svg",
        id: "production.basic",
        title: "Production Log",
        subItems: [
          {
            href: "/prodtrack",
            id: "prodtrack.basic",
            title: "Production Log",
          },
          {
            href: "/prodtrack/add",
            id: "production.basic.add",
            title: "New Production Log",
          },
        ],
      },
    ],
    title: "Supply & Production",
  },
  {
    title: "Team Management",
    items: [
      {
        href: "/team",
        icon: "/images/icons/team.svg",
        id: "team.basic",
        title: "Team",
        permissions: {
          role: StoreRoles.Owner,
        },
        subItems: [
          {
            href: "/team",
            id: "team.basic",
            title: "Team",
          },
          {
            href: "/team/add",
            id: "team.basic.add",
            title: "Add Team Member",
          },
        ],
      },
      {
        href: "/payroll",
        icon: "/images/icons/payroll.svg",
        id: "payroll.basic",
        title: "Payroll",
        subItems: [
          {
            href: "/payroll",
            id: "payroll.basic",
            title: "Payroll",
          },
          {
            href: "/payroll/add",
            id: "payroll.basic.add",
            title: "New Payroll",
          },
        ],
        permissions: {
          adminOnly: true,
        },
      },
      {
        href: "/departments",
        icon: "/images/icons/team.svg",
        id: "departments.basic",
        title: "Departments",
        permissions: {
          adminOnly: true,
        },
        subItems: [
          {
            href: "/departments",
            id: "departments.basic",
            title: "Overview",
          },
          {
            href: "/departments/add",
            id: "departments.basic.add",
            title: "Add Department",
          },
        ],
      },
      {
        href: "/org-chart",
        icon: "/images/icons/team.svg",
        id: "org.basic",
        title: "Org Chart",
        permissions: {
          adminOnly: true,
        },
        subItems: [
          {
            href: "/org-chart",
            id: "org.basic",
            title: "Overview",
          },
        ],
      },
    ],
  },
  {
    title: "Store Management",
    items: [
      {
        href: "/reports",
        icon: "/images/icons/team.svg",
        id: "report.basic",
        title: "Reports",
        permissions: {
          role: StoreRoles.Owner,
        },
        subItems: [
          {
            href: "/reports",
            id: "report.basic",
            title: "Overview",
          },
        ],
      },
      {
        href: "/activity",
        icon: "/images/icons/activity.svg",
        id: "activity.basic",
        title: "Activity",
        permissions: {
          role: StoreRoles.Owner,
        },
        subItems: [
          {
            href: "/activity",
            id: "activity.basic",
            title: "Overview",
          },
        ],
      },
      {
        href: "/files",
        icon: "/images/icons/files.svg",
        id: "media.basic",
        title: "File Library",
      },

      {
        href: "/price-calculator",
        id: "price-calculator.basic",
        icon: "/images/icons/dashboard.svg",
        title: "Price Calculator",
      },
      {
        href: "/settings",
        icon: "/images/icons/settings.svg",
        id: "settings.basic",
        title: "Settings",
        permissions: {
          role: StoreRoles.Owner,
        },
        subItems: [
          {
            href: "/settings",
            id: "settings.basic",
            title: "Settings",
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        href: "/admin",
        icon: "/images/icons/dashboard.svg",
        id: "admin.basic",
        title: "Dashboard",
        subItems: [
          {
            href: "/admin",
            id: "admin.basic",
            title: "Overview",
          },
          {
            href: "/admin/inventory/categories",
            id: "admin.inventory",
            title: "Product Categories",
          },
          {
            href: "/admin/users",
            id: "admin.users",
            title: "Users",
          },
          {
            href: "/admin/features",
            id: "admin.features",
            title: "Features",
          },
        ],
        permissions: {
          adminOnly: true,
        },
      },
    ],
    title: "Admin",
  },
];

const canAccessFeature = (
  currentStore: Store | undefined,
  user: User | undefined,
  itemPermissions: PermissionsModelV1 | null,
): boolean => {
  //   If no permissions are set, show the feature
  if (!itemPermissions) {
    return true;
  }

  const userRoles = user?.store_roles || [];

  // Hide permissions for users without a store
  if (!currentStore) {
    return false;
  }

  const userRoleOnStore =
    userRoles.find((role) => role.store_id === currentStore.id)?.role || "";

  // If all users have access, show the feature
  if (itemPermissions.allUsers) {
    return true;
  }

  // If the feature is only available in dev mode, only show in dev mode
  if (itemPermissions.devOnly) {
    return process.env.NODE_ENV === "development";
  }

  // If permissions are set, wait for the user object to be available before showing permissions
  if (!user) {
    return false;
  }

  // If the feature is only available to admins, only show to admins
  if (itemPermissions.adminOnly) {
    return Boolean(user.is_superuser);
  }

  // If the feature is only available for a specific permission ID, check if the user has the permission
  // if (itemPermissions.permissionId) {
  //   const userFeatures =
  //     user?.available_features.map((feature) => feature.code) || [];
  //   return userFeatures.includes(itemPermissions.permissionId);
  // }

  //   An owner has access to all features
  if (userRoleOnStore === StoreRoles.Owner) {
    return true;
  }

  // Only show features the role has permissions to view
  if (itemPermissions.role) {
    return userRoleOnStore === itemPermissions.role;
  }

  return false;
};

/**
 * Build the navigation menu based on what the user has access to.
 * @param currentStore
 * @param user
 */
const buildNavGroups = (
  currentStore: Store | undefined,
  user: User | undefined,
): NavigationGroup[] => {
  // Show only sections with at least one item
  return allNavGroups
    .map((navGroup) => {
      const hasNavGroupPermission = canAccessFeature(
        currentStore,
        user,
        navGroup.permissions || null,
      );

      if (!hasNavGroupPermission) {
        return null;
      }

      const items: NavigationItem[] = navGroup.items.filter((item) => {
        return canAccessFeature(currentStore, user, item.permissions || null);
      });

      return {
        ...navGroup,
        items,
      };
    })
    .filter(
      (navGroup): navGroup is NavigationGroup =>
        navGroup !== null && navGroup.items.length > 0,
    );
};

export { buildNavGroups };
